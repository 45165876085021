* {
    outline: 0;
  }
  
  .App {
    font-family: sans-serif;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  
  input {
    display: block;
    margin: 1em;
    margin-bottom: 5em;
    padding: 1em;
    border-radius: 8px;
    border-style:solid;
    border: 3px solid #e4e6e8;
    transition: 0.1s ease;
    width: 100%;
  }

  textarea{
    display: block;
    margin: 1em;
    padding: 1em;
    border-radius: 8px;
    border-style: solid;
    border: 3px solid #e4e6e8;
    transition: 0.1s ease;
    width: 100%;
  }

  .textareasendreply{
    display: block;
    margin-bottom: 7px;
    padding:1em;
    border-radius: 8px;
    border-style: solid;
    border: 3px solid #e4e6e8;
    transition: 0.1s ease;
    width: 100%;
  }

  button {
    display: block;
    margin: 1em;
    border-radius: 8px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
    background-color: rgba(12,180,146,255); 
    color:white;
    padding:0em;
    font-weight: bold; 
  }
  
  span{
    display: block;
    margin: 1em;
    font-size: large;
  
  }
  
  input:hover {
    border-color: rgba(9,144,117,255);
  }
  textarea:hover {
    border-color: rgba(9,144,117,255);
  }
  
  button:hover {
    background-color: rgba(9,144,117,255);
    color: white;
  }
  
  .afterResolvedButtonStyle{
    display: block;
    margin: 0;
    border-radius: 8px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
    background-color: #f7f7f7;
    color: #bbb;
    padding:1em;
    font-weight: bold; 
  }
 
  .afterResolvedButtonStyle:hover{
    background-color: #f7f7f7;
    color: #bbb;
  }

  .submitFeedbackButton {
    display: block;
    margin: 1em;
    border-radius: 8px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
    background-color: rgba(12,180,146,255); 
    color:white;
    padding:1em;
    font-weight: bold; 
  }